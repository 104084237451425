<template>
  <div class="bg">
    <div class="bgz">
      <img src="" alt="" class="xiaor" id="xiaor" />
      <img src="../assets/phb.png" alt="" class="phb" />
      <div class="phxz">
        <div @click="xzd(1)" :class="{ 'pitch': checked == 1 }" v-if="ranking_day == '2'">日排行</div>
        <div @click="xzd(3)" :class="{ 'pitch': checked == 3 }" v-if="ranking_department == '2'">部门排行</div>
        <div @click="xzd(2)" :class="{ 'pitch': checked == 2 }" v-if="ranking_all == '2'">总排行</div>
        <div @click="xzd(4)" :class="{ 'pitch': checked == 4 }" v-if="ranking_score_all == '2'">总分排行</div>

      </div>
      <div class="phblb" @scroll="scrollEvent($event)">
        <div class="pmx" v-for="(val, i) in phbsj" :key="i">
          <div class="djmk">
            <span v-if="i > 2">{{ i + 1 }}</span>
            <img src="../assets/d1.png" alt="" v-if="i == 0" />
            <img src="../assets/d2.png" alt="" v-if="i == 1" />
            <img src="../assets/d3.png" alt="" v-if="i == 2" />
          </div>
          <div class="namek" v-if="ranking_time == 1">
            {{ checked != 4 ? val.user.username : val.username }}
          </div>
          <div class="nameks" v-if="ranking_time == 2">
            {{ checked != 4 ? val.user.username : val.username }}
          </div>
          <div class="nameks_time" v-if="ranking_time == 2">
            <span style="color: #ef842e;">{{ checked != 4 ? val.time : val.total_time }}</span><span
              style="font-size: 0.22rem;margin-left: 0.02rem;">秒</span>
          </div>
          <div class="fsk">
            <span class="fs">{{ checked != 4 ? val.score : val.total_score }}</span>
            <span class="fm"> 分</span>
          </div>
        </div>
        <van-loading size="24px" class="loading" color="#0094ff" text-color="#0094ff" v-if="emptys">加载中...</van-loading>
        <div class="finish" v-if="!emptys"> —— 已经到底了 —— </div>
      </div>
      <div class="phbbtn" @click="fhsy">返回首页</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phbsj: [],
      pageSize: 20,
      page: 1,
      emptys: true, //是否加载完所有的数据
      information: true, //是否正在加载中
      timer: null, //防抖节流

      checked: "",//当前选择的
      ranking_day: "",//日排行
      ranking_department: "",//部门排行
      ranking_all: "",//总排行榜
      ranking_score_all: "",//总分排行榜
      ranking_time: 1,
      cancelToken: null,
    };
  },
  created() { },
  mounted() {
    if (
      window.localStorage.getItem("coid") == "null" ||
      window.localStorage.getItem("coid") == "" ||
      window.localStorage.getItem("coid") == undefined
    ) {
      this.$router.push({
        path: `/`,
      });
      return;
    }
    if (
      window.localStorage.getItem("nameus") == null ||
      window.localStorage.getItem("nameus") == "" ||
      window.localStorage.getItem("nameus") == undefined
    ) {
      this.$router.push({
        path: `/?activity_id=${window.localStorage.getItem("coid")}`,
      });
    }
    this.ranking_day = window.localStorage.getItem("ranking_day");
    this.ranking_department = window.localStorage.getItem("ranking_department");
    this.ranking_all = window.localStorage.getItem("ranking_all");
    this.ranking_score_all = window.localStorage.getItem("ranking_score_all");
    if (this.ranking_day == '2') {
      this.checked = 1
      this.listbox();
      return
    }
    if (this.ranking_department == '2') {
      this.checked = 3
      this.listbox();
      return
    }
    if (this.ranking_all == '2') {
      this.checked = 2
      this.listbox();
      return
    }
    if (this.ranking_score_all == '2') {
      this.checked = 4
      this.listbox();
      return
    }
  },
  methods: {
    xzd(a) {
      if (this.checked == a) {
        return
      }
      this.checked = a
      this.information = true;
      this.phbsj = []
      // pageSize: 20,
      this.page = 1
      this.emptys = true //是否加载完所有的数据
      // information: true, //是否正在加载中
      this.timer = null //防抖节流
      this.cancelToken.cancel('用户切换选项请求已被取消。');
      this.listbox("true");


    },
    // 监听是否触底
    scrollEvent(e) {
      // console.log(111);
      if (this.information && this.emptys) {
        if (
          e.target.scrollTop + e.target.offsetHeight >=
          e.target.scrollHeight - 50
        ) {
          // //防抖节流
          clearInterval(this.timer);
          // this.jzz = 0;
          this.timer = setTimeout(() => {
            // console.log("啦啦啦啦啦触发了");

            this.page += 1;
            this.information = false;
            this.listbox("true");

            // console.log("jia", this.flys);
            // this.hqzylb();
          }, 200);
        }
      }
      // if()
    },

    listbox(val) {

      // 取消请求

      this.cancelToken = this.$axios.CancelToken.source();
      this.$axios
        .post(
          "/getRankList",
          `page=${this.page}&pageSize=${this.pageSize
          }&score=1&activity_id=${window.localStorage.getItem("coid")}&type=${this.checked}&department_id=${window.localStorage.getItem("department_id")}&company_id=${window.localStorage.getItem("company_id")}`,
          { cancelToken: this.cancelToken.token }
        )
        .then((red) => {
          // console.log(red);
          if (red.data.code == 0) {
            // console.log("获取成功")
            // console.log(red.data.data.rankList)
            if (red.data.data.type==(this.checked+'')) {
              this.phbsj = this.phbsj.concat(red.data.data.rankList);
              this.information = true;
              this.ranking_time = red.data.data.ranking_time
              if (red.data.data.rankList.length < this.pageSize) {
                this.emptys = false;
              }
              // console.log(red.data.data.rankList.length)
              if (val != "true") {
                if (red.data.data.ip_img != "" && red.data.data.ip_img != null) {
                  // console.log(2)
                  document.getElementById("xiaor").src = red.data.data.ip_img;
                } else {
                  // console.log(1)
                  document.getElementById(
                    "xiaor"
                  ).src = require("../assets/xr.png");
                }
              }
            }

          } else {
            this.ts("网络异常获取排行榜失败请退出重试");
          }
        });
    },
    fhsy() {
      this.$router.push({
        path: `/?activity_id=${window.localStorage.getItem("coid")}`,
      });
    },
  },
};
</script>
<style scoped>
.pitch {
  color: #366CFF;
  border-bottom: 0.01rem solid #0EF5F7;
  font-weight: bold;


}

.phxz div {
  padding-bottom: 0.1rem;
}

.phxz {

  position: absolute;
  top: 1.3rem;
  left: 50%;
  transform: translate(-50%);
  width: 5.9rem;
  display: flex;
  font-size: 0.3rem;
  justify-content: space-around;
  color: rgb(184, 190, 188);
  /* border-bottom: 0.01rem solid rgb(217, 166, 166); */
}

.finish {
  width: 100%;
  color: #B2B2B2;
  /* background-color: #f7f7f7; */

  text-align: center;
  font-size: 0.25rem;
  padding: 0.2rem 0 0.2rem;
}

.loading {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}

.xiaor {
  position: absolute;
  width: 1.15rem;
  top: 0;
  left: 1.55rem;
}

.fsk .fm {
  font-size: 0.25rem;
  /* line-height: 0.63rem; */
}

.fsk .fs {
  font-size: 0.4rem;
  color: #497eed;
  /* line-height: 0.63rem; */
}

.fsk {
  text-align: right;
  line-height: 0.15rem;
  position: absolute;
  top: 50%;
  right: 0.05rem;
  transform: translateY(-50%);
  height: 0.5rem;
  width: 1.5rem;
  /* background-color: #dd80c1; */
}

.namek {
  position: absolute;
  top: 50%;
  left: 1.2rem;
  transform: translateY(-50%);
  height: 0.63rem;
  width: 3.1rem;
  /* background-color: #7d86c6; */
  line-height: 0.63rem;
  font-size: 0.3rem;
  text-align: left;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nameks {
  position: absolute;
  top: 50%;
  left: 1.2rem;
  transform: translateY(-50%);
  height: 0.63rem;
  width: 1.9rem;
  /* background-color: #7d86c6; */
  line-height: 0.63rem;
  font-size: 0.3rem;
  text-align: left;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nameks_time {
  position: absolute;
  top: 50%;
  left: 3.16rem;
  transform: translateY(-50%);
  height: 0.63rem;
  width: 1.15rem;
  /* background-color: #df8551; */
  line-height: 0.63rem;
  font-size: 0.3rem;
  text-align: right;
  /* font-weight: bold; */
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.djmk img {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.djmk {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 0.63rem;
  width: 0.63rem;
  /* background-color: #0d1d7f; */
  font-size: 0.45rem;
  font-weight: bold;
  color: #3e71dd;
  line-height: 0.63rem;
  /* font-family:'Adobe 黑体 Std'; */
  font-style: italic;
  /* font-style:oblique; */
  /* font-family: "Clarendon Blk BT", "YouSheBiaoTiHei"; */
}

.pmx {
  position: relative;
  height: 1.07rem;
  width: 100%;
  /* background-color: rgb(141, 162, 180); */
  border-bottom: 1px dashed #d6e3ff;
}

.phblb {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 2rem;
  /* background-color: wheat; */
  width: 5.9rem;
  height: 9.25rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.phb {
  position: absolute;
  height: 11.5rem;
  top: 0rem;
  left: 50%;
  transform: translate(-50%);
}

.phbbtn {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 4.3rem;
  height: 0.85rem;
  top: 12rem;
  font-size: 0.32rem;
  background-color: #0d1d7f;
  border-radius: 0.5rem;
  color: rgb(255, 255, 255);
  line-height: 0.9rem;
  letter-spacing: 0.02rem;
}

.bg .bgz {
  position: absolute;
  width: 100%;
  /* width:6.8rem ; */
  left: 50%;
  height: 13rem;
  top: 50%;
  transform: translate(-50%, -50%);
  /* overflow: auto; */
}

.bg {
  /* background: url(../assets/bg.png) no-repeat; */
  /* background-color: #f44b00; */
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 100%;
  height: 100vh;
  min-height: 13rem;
  background-size: cover;
  background-position: center center;
  /* max-height: 7rem; */
  max-width: 7.5rem;
  left: 0rem;
  max-height: 18rem;
  overflow: hidden;
}
</style>